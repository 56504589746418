<template>
  <v-app-bar app clipped-left clipped-right color="white" light>
    <v-toolbar-title class="align-center d-flex">
      <!-- <span class="logo-icon">
        <img src="@/assets/logo-icon.png" />
      </span> -->
      <span class="logo-text ml-2">
        <img src="@/assets/images/logo-dark.png" height="36px" class="mt-2" />
      </span>
    </v-toolbar-title>
    <v-app-bar-nav-icon
      class="d-block"
      @click="
        $vuetify.breakpoint.smAndDown
          ? setSidebarDrawer(!Sidebar_drawer)
          : $emit('input', !value)
      "
    />
    <v-spacer />
    <!---right part -->
    <!-- <v-btn
      dark
      color="primary"
      class="elevation-0"
      href="https://www.wrappixel.com/templates/adminpro-vuetify-dashboard/"
      >Upgrade to Pro</v-btn
    > -->
    <span><v-icon>mdi-wallet</v-icon> Wallet: {{usrWallet}}</span>
    <v-menu bottom left transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn light icon v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-for="(item, i) in userprofile" :key="i" @click="href(item)">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <logoutDialog
    :dialog.sync="dialog"
    maxWidth="320"
    @close="dialog=false"
    />
  </v-app-bar>
</template>
<script>
// Utilities
import { mapState, mapMutations } from "vuex"
import router from "../../router"
import logoutDialog from "../../components/mainComponents/LogoutDialog.vue"

export default {
  name: "Header",

  components: {
    logoutDialog
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    usrData: {},
    dialog: false,
    //usrWallet: '',
    userprofile: [
      { title: "My Profile", to: "/pages/profile" },
      //{ title: "My Balance" },
      //{ title: "Inbox" },
      //{ title: "Account Setting" },
      { title: "Logout" },
    ],
  }),

  computed: {
    ...mapState(["Sidebar_drawer", "usrWallet"]),
  },

  methods: {
    ...mapMutations({
            setSidebarDrawer: "SET_SIDEBAR_DRAWER",
            set_usrWallet: "set_usrWallet",
    }),
    href(item) {
        console.log(item)
        if(item.to){
            router.push(item.to)
        }
        else if(item.title === 'Logout') {
            this.dialog = true
        }
    },
  },

  async created() {
    console.log('this.usrWallet: ', this.usrWallet)
    this.usrData = await JSON.parse(localStorage.getItem("user"))
    //console.log('this.usrData ', this.usrData)
    //this.usrWallet = (this.usrData.wallet && this.usrData.wallet.wallet) ? this.usrData.wallet.wallet : 'N/A'
    this.set_usrWallet((this.usrData.wallet && this.usrData.wallet.wallet) ? this.usrData.wallet.wallet : 'N/A')
  }
};
</script>
