<template>
  <v-navigation-drawer
    v-model="Sidebar_drawer"
    :dark="SidebarColor !== 'dark'"
    :color="SidebarColor"
    mobile-breakpoint="960"
    clipped
    :right="$vuetify.rtl"
    mini-variant-width="70"
    :expand-on-hover="expandOnHover"
    app
    id="main-sidebar"
  >
    <v-list dense nav shaped>
      <!---USer Area -->
      <v-list-item two-line class="px-0">
        <v-list-item-avatar>
          <!-- <img src="https://randomuser.me/api/portraits/men/81.jpg" /> -->
          <img src="`../../assets/users/${usrData.id}.jpeg`" onerror="this.src='nouser.jpg'" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{usrData.nombre}} </v-list-item-title>
          <v-list-item-subtitle class="caption">{{usrData.email}}</v-list-item-subtitle>
          <v-list-item-subtitle class="caption">{{usrData.nb_perfil}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <!---USer Area -->
      <v-divider></v-divider>
       <!--  <v-list-item-group
            v-model="model"
            mandatory
            color="indigo"
        > -->
            <!---Sidebar Items -->
            <v-list-item
                v-for="item in items"
                :key="item.title"
                :to="item.to"
                :active-class="`primary--text`"
                link
            >
                <v-list-item-icon v-if="!item.subItems">
                <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content v-if="!item.subItems">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>

                <v-list-group
                v-if="item.subItems && item.isVisible"
                class="menu-group"
                >
                    <template v-slot:activator>
                        <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item
                        v-for="subItem in item.subItems"
                        :key="subItem.title"
                        :to="subItem.isVisible ? subItem.to : ''"
                        :active-class="`primary--text`"
                        link
                    >
                        <v-list-item-icon v-if="subItem.isVisible">
                        <v-icon>{{ subItem.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content v-if="subItem.isVisible">
                        <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>

            </v-list-item>
        <!-- </v-list-item-group> -->
      <v-list-item two-line class="px-0">
        <v-list-item-content>
        <v-divider></v-divider>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        key="Logout"
        :active-class="`primary--text`"
        class="mt-auto"
        link
        @click.stop="dialog1 = true"
      >
        <v-list-item-icon>
          <v-icon>mdi-location-exit</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title> Cerrar Sesión </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!---Sidebar Items -->
    </v-list>
<!-- 
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Salir de Ticktack?
        </v-card-title>

        <v-card-text>
          Esto cerrará su sesión actual de usuario.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="warning"
            text
            @click="dialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="logout"
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
 -->
    <logoutDialog
    :dialog.sync="dialog1"
    maxWidth="320"
    @close="dialog1=false"
    />
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex"
import logoutDialog from "../../components/mainComponents/LogoutDialog.vue"

export default {
  name: "Sidebar",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    logoutDialog,
  },
  data: () => ({
    usrData: {},
    dialog1: false,
    model: 1,
    items: [],
  }),
  computed: {
    ...mapState(["SidebarColor", "SidebarBg"]),
    Sidebar_drawer: {
      get() {
        return this.$store.state.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      },
    },
  },
  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },

  methods: {
   /*  async logout() {
        console.log('Click en Logout')
        localStorage.removeItem("user")
        localStorage.removeItem("usrToken")
        location.href= process.env.VUE_APP_WEB_URL
    } */
  },
  async created() {
        this.usrData = await JSON.parse(localStorage.getItem("user"))
        this.items = [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        to: "/dashboard/user_dashboard",
      },
      {
        title: "Invertir",
        icon: "mdi-finance",
        to: "/pages/invertir",
      },
      {
        title: "Programa de Referidos",
        icon: "mdi-account-group",
        to: "/pages/referidos",
      },
      {
        title: "Transacciones",
        icon: "mdi-cash-clock",
        to: "/pages/transacciones",
      },
      {
        title: "Deposito de Fondos",
        icon: "mdi-cash-plus",
        to: "/pages/depositos",
      },
      {
        title: "Retiro de Fondos",
        icon: "mdi-cash-minus",
        to: "/pages/retiros",
      },
      {
        title: "Perfil de usuario",
        icon: "mdi-account-circle",
        to: "/pages/profile",
      },
      {
        title: "Administración",
        icon: "mdi-application-cog-outline",
        isVisible: this.usrData.nb_perfil !== 'Jugador',
        subItems: [
            {
                title: "Gestionar Depositos",
                icon: "mdi-checkbox-marked-circle-plus-outline",
                to: "/pages/administracion/gestdepositos",
                isVisible: this.usrData.nb_perfil !== 'Jugador'
            },
            {
                title: "Gestionar Retiros",
                icon: "mdi-send-check-outline",
                to: "/pages/administracion/gestretiros",
                isVisible: this.usrData.nb_perfil !== 'Jugador'
            },
            {
                title: "Tipo Wallet Users",
                icon: "mdi-wallet",
                to: "/pages/administracion/tipowallets",
                isVisible: this.usrData.nb_perfil == 'Super-Admin'
            },
            {
                title: "Tipo Wallet Master",
                icon: "mdi-wallet",
                to: "/pages/administracion/tipowalletsmaster",
                isVisible: this.usrData.nb_perfil == 'Super-Admin'
            },
            {
                title: "Wallets Master",
                icon: "mdi-wallet",
                to: "/pages/administracion/walletsmaster",
                isVisible: this.usrData.nb_perfil == 'Super-Admin'
            },
            {
                title: "configuración",
                icon: "mdi-cog",
                to: "/pages/administracion/configuracion",
                isVisible: this.usrData.nb_perfil == 'Super-Admin'
            }
        ]
      },
      {
        title: "Jugar Tu Ticktack",
        icon: "mdi-controller-classic",
        to: "/pages/playticktack",
      },
    ]
  }
};
</script>
<style lang="scss">
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  .v-navigation-drawer__border {
    display: none;
  }
  .v-list {
    padding: 8px 15px;
  }
  .v-list-item {
    min-height: 35px;
    &__icon--text,
    &__icon:first-child {
      justify-content: center;
      text-align: center;
      width: 20px;
    }
  }
  .v-list-item__icon i {
    width: 20px;
  }
  .icon-size .v-list-group__items i {
    width: 16px;
    font-size: 16px;
  }
  .profile-bg {
    &.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
      opacity: 1;
    }
    .v-avatar {
      padding: 15px 0;
    }
  }
  .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
}

.theme--dark.v-navigation-drawer {
  background-color: #242a33 !important;
}

.menu-group {
    margin-left: -8px;
}
.v-list-group__items > .v-list-item {
    padding-left: 24px !important;
}
</style>