<template>
    <v-dialog
      :value="lDialog"
      persistent
      :max-width="maxWidth"
    >
      <v-card>
        <v-card-title class="text-h5">
          Salir de Ticktack?
        </v-card-title>

        <v-card-text>
          Esto cerrará su sesión actual de usuario.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="warning"
            text
            @click="close"
          >
            No
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="logout"
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'logoutDialog',
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: '290'
    },
  },
  data: () => ({
    lDialog: false
  }),

  watch: {
    dialog (newValue, oldValue) {
        console.log('dialog: ', newValue, oldValue)
      this.lDialog = newValue // Update when the prop changes
    }
  },

    mutations: {
        SET_DIALOG (props, payload) {
            props.dialog = payload
        },
    },

  methods: {
        logout() {
            console.log('Click en Logout')
            localStorage.removeItem("user")
            localStorage.removeItem("usrToken")
            location.href= process.env.VUE_APP_WEB_URL
        },
        close() {
            //this.lDialog = false
            this.$emit('close');
        }, 
  }

}
</script>

<style>

</style>